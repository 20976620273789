<template>
    <div id="app">
        <div id="top">招生数据排行</div>
        <div class="index-float">
            <label style="margin-left:5%">请选择时间：</label>
            <a-radio-group default-value="0" button-style="solid" style="margin-right:12px" size="small" @change="radioChange">
                <a-radio-button value="0">
                    今天
                </a-radio-button>
                <a-radio-button value="1">
                    昨天
                </a-radio-button>
                <a-radio-button value="2">
                    最近7天
                </a-radio-button>
                <a-radio-button value="3">
                    最近30天
                </a-radio-button>
            </a-radio-group>
            <a-range-picker size="small" style="width:220px" @change="handleChange" :value="Time">
                <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                        {{ current.date() }}
                    </div>
                </template>
            </a-range-picker>
        </div>
        <!-- 访问排行 -->

        <div class="tow">
            <h1 class="tow-h1">访问排行</h1>
            <a-table :columns="columns"
                     :data-source="Visit.FansList"
                     rowKey="ID"
                     :pagination="false"
                     :scroll="{ y: 650 }"
                     style="text-align:center;">
                <span slot="SerialNO" slot-scope="text,record">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{record.SerialNO}}</a-col>
                </span>
                <span slot="UHeadImg" slot-scope="text,record">
                    <a-col flex="48px" class="ranking-timg"><img :src="record.UHeadImg" /> </a-col>
                </span>
            </a-table>
            <a-pagination v-model="pageIndex" :total="Visit.visitSum" @change="VisitData()" style="text-align:right;margin-right:20px;"/>
        </div>
        <!--拉新排行-->

        <div class="tow">
            <h1 class="tow-h1">拉新排行</h1>
            <a-table :columns="columna"
                     :data-source="New.NewList"
                     rowKey="ID"
                     :pagination="false"
                     :scroll="{ y: 650 }"
                     style="text-align:center;">
                <span slot="SerialNO" slot-scope="text,record">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{record.SerialNO}}</a-col>
                </span>
                <span slot="UHeadImg" slot-scope="text,record">
                    <a-col flex="48px" class="ranking-timg"><img :src="record.UHeadImg" /> </a-col>
                </span>
            </a-table>
            <a-pagination v-model="pageIndex2" :total="New.newSun" @change="NewData()" style="text-align:right;margin-right:20px;" />

        </div>
        <!--成交排行-->
        <div class="tow">
            <h1 class="tow-h1">成交排行</h1>
            <a-table :columns="columnd"
                     :data-source="Deal.OrderList"
                     rowKey="ID"
                     :pagination="false"
                     :scroll="{ y: 650 }"
                     style="text-align:center;">
                <span slot="SerialNO" slot-scope="text,record">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{record.SerialNO}}</a-col>
                </span>
                <span slot="UHeadImg" slot-scope="text,record">
                    <a-col flex="48px" class="ranking-timg"><img :src="record.UHeadImg" /> </a-col>
                </span>
            </a-table>
            <a-pagination v-model="pageIndex3" :total="Deal.dealSum" @change="DealData()" style="text-align:right;margin-right:20px;" />
        </div>
    </div>
</template>
<script>
    import "ant-design-vue/dist/antd.css";
    import Vue from "vue";
    import ant from "ant-design-vue";
    import util from "@/Plugin/util.js";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import moment from "moment";
    import http from "@/Plugin/Http.js";
    Vue.prototype.$zhCN = zh_CN;
    Vue.use(ant);
    const columns = [
        {
            title: '序号',
            dataindex: 'SerialNO',
            width: 100,
            scopedSlots: {
                customRender: 'SerialNO'
            }

        },
        {
            title: '头像',
            dataIndex: 'UHeadImg',
            scopedSlots: {
                customRender: 'UHeadImg'
            }

        },
        {
            title: '用户名称',
            dataIndex: 'Name',
            key: 'Name',


        },
        {
            title: '访问数量',
            dataIndex: 'TargetCount',

        },
    ];
    const columna = [
        {
            title: '序号',
            dataindex: 'SerialNO',
            width: 100,
            scopedSlots: {
                customRender: 'SerialNO'
            }

        },
        {
            title: '头像',
            dataIndex: 'UHeadImg',
            scopedSlots: {
                customRender: 'UHeadImg'
            }

        },
        {
            title: '用户名称',
            dataIndex: 'Name',
            key: 'Name',


        },
        {
            title: '拉新数量',
            dataIndex: 'TargetCount',

        },
    ];
    const columnd = [
        {
            title: '序号',
            dataindex: 'SerialNO',
            width: 100,
            scopedSlots: {
                customRender: 'SerialNO'
            }

        },
        {
            title: '头像',
            dataIndex: 'UHeadImg',
            scopedSlots: {
                customRender: 'UHeadImg'
            }

        },
        {
            title: '用户名称',
            dataIndex: 'Name',
            key: 'Name',


        },
        {
            title: '成交数量',
            dataIndex: 'TargetCount',

        },
    ];
    export default {
        name: "",
        data() {
            return {
                Time: [],
                showList: true,
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                Visit: {
                    visitSum: 0,
                    FansList: [],
                },
             
                Deal: {
                    dealSum: 0,
                    OrderList: [],
                },
                New: {
                    newSun: 0,
                    NewList:[],
                },
                columna,
                columns,
                columnd,
                CustomerID: "",
                pageSize: 20,
                pageIndex: 1, 
                pageIndex2: 1,
                pageIndex3: 1,
            };
        },

        methods: {
            //方法
            //loadData() {
            //    var self = this;
            //  /*  console.log("排行" + self.CustomerID);*/
            //    console.log(self.BeginDate, self.EndDate);
            //    var op = {
            //        url: "/OperationsModule/Operations2/GetRank2",
            //        data: {
            //            BeginDate: self.BeginDate,
            //            EndDate: self.EndDate,
            //            pageSize: self.pageSize,
            //            pageIndex: self.pageIndex,
            //            CustomerID: self.CustomerID,
            //        },
            //        OnSuccess: function (res) {
            //            console.log("res：", res)
            //            ////访问
            //            //self.Visit.FansList = res.data.AgentItemVisit.Data;
            //            //self.Visit.visitSum = parseInt(res.data.AgentItemVisit.Total);

            //            self.New.NewList = res.data.AgentItemNew.Data;
            //            self.New.newSun = parseInt(res.data.AgentItemNew.Total);
            //            //console.log(res.data.AgentItemVisit.Data);
            //            //console.log(res.data.AgentItemVisit.Message);
            //            //成交
            //            self.Deal.OrderList = res.data.AgentItemDeal.Data;
            //            self.Deal.dealSum = parseInt(res.data.AgentItemDeal.Total);

            //            //self.OrderList = res.data.AgentItemDeal;
            //        }
            //    };
            //    http.Post(op);
            //},
            ///访问排行
            VisitData() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations2/GetVisitrank",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        pageSize: self.pageSize,
                        pageIndex: self.pageIndex,
                        CustomerID: self.CustomerID,
                    },
                    OnSuccess: function (data) {
                        console.log("data",data);
                        //访问
                        self.Visit.FansList = data.data.Data;
                        self.Visit.visitSum = parseInt(data.data.Total);
                       
                    }
                };
                http.Post(op);
            },
            ///拉新排行
            NewData() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations2/GetNewrank",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        pageSize: self.pageSize,
                        pageIndex: self.pageIndex2,
                        CustomerID: self.CustomerID,
                    },
                    OnSuccess: function (res) {
                        console.log("res2", res);
                        //拉新
                        self.New.NewList = res.data.Data;
                        self.New.newSun = parseInt(res.data.Total);

                    }
                };
                http.Post(op);
            },
            ///成交排行
            DealData() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations2/GetDealtrank",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        pageSize: self.pageSize,
                        pageIndex: self.pageIndex3,
                        CustomerID: self.CustomerID,
                    },
                    OnSuccess: function (res) {
                        console.log("res3", res);
                        //成交
                        self.Deal.OrderList = res.data.Data;
                        self.Deal.dealSum = parseInt(res.data.Total);

                    }
                };
                http.Post(op);
            },
            radioChange: function (item) {
                var self = this;
                var Time = "";
                switch (item.target.value) {
                    case "0":
                        Time = [
                            moment(new Date(), "YYYY-MM-DD"),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "1":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                        ];
                        break;
                    case "2":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 7)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "3":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 30)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "4":
                        var year = new Date().getFullYear();
                        Time = [
                            moment(new Date(moment(year + "-01-01")), "YYYY-MM-DD"),
                            moment(new Date(moment(year + "-06-30")), "YYYY-MM-DD"),
                        ];
                        break;
                }
                self.handleChange(Time);
            },
            getCurrentStyle(current) {
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                }
                return style;
            },
            handleChange(value) {
                var self = this;
                if (value.length == 0) {
                    self.BeginDate = util.formatDate(new Date());
                    self.EndDate = util.formatDate(new Date());
                    value = [
                        moment(new Date(), "YYYY-MM-DD"),
                        moment(new Date(), "YYYY-MM-DD"),

                    ];
                    self.$message.error("必须选择日期", 1.5, function () { });
                } else {
                    self.BeginDate = util.formatDate(value[0]._d);
                    self.EndDate = util.formatDate(value[1]._d);
                }
                self.Time = value;

                /*self.loadData();*/
                self.VisitData();
                self.NewData();
                self.DealData();
                //console.log("时间");
                //console.log(self.BeginDate, self.EndDate);
            }

        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件

        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            self.Time = [
                moment(new Date(), "YYYY-MM-DD"),
                moment(new Date(), "YYYY-MM-DD"),
            ];
            //self.loadData();
            self.VisitData();
            self.NewData();
            self.DealData();
         
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }
</script>
<style>
    #app {
        background-color: #F0F2f5;
    }

    #top {
        text-align: center;
        color: #186ee5;
        font-size: 2.5em;
        font-weight: 600;
    }

    。
    .index-float {
        margin-left: 200px;
    }

    .tow {
        width: 30%;
        height: 840px;
        float: left;
        background-color: #ffffff;
        margin-top: 20px;
        margin-left: 2%
    }
    .tow-h1 {
   margin-left:40%;
   font-size:1.5em;
   font-weight:700;
    }
    .ranking-tnumbe {
        font-size: 24px;
        font-weight: 700;
    }
    .ranking-list:nth-child(1) .ranking-tnumbe {
        color: #ff0e0e;
    }

    .ranking-list:nth-child(2) .ranking-tnumbe {
        color: #ffb812;
    }

    .ranking-list:nth-child(3) .ranking-tnumbe {
        color: #4eb2ff;
    }
    .ranking-timg {
        position: relative;
    }

        .ranking-timg img {
            width: 36px;
            height: 36px;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
        }
    .ranking-list2 .ranking-timg img {
        width: 68px;
        height: 36px;
        border-radius: 4px;
    }
    .ranking-list:nth-child(1) .ranking-timg::after {
        content: "";
        position: absolute;
        top: -2px;
        right: 4px;
        width: 18px;
        height: 18px;
        background: url(../../assets/imges/ranking1.svg) no-repeat center;
        background-size: cover;
        transform: rotate(40deg)
    }
    .ranking-list:nth-child(2) .ranking-timg::after {
        content: "";
        position: absolute;
        top: -2px;
        right: 4px;
        width: 18px;
        height: 18px;
        background: url(../../assets/imges/ranking2.svg) no-repeat center;
        background-size: cover;
        transform: rotate(40deg)
    }

    .ranking-list:nth-child(3) .ranking-timg::after {
        content: "";
        position: absolute;
        top: -2px;
        right: 4px;
        width: 18px;
        height: 18px;
        background: url(../../assets/imges/ranking3.svg) no-repeat center;
        background-size: cover;
        transform: rotate(40deg)
    }

    .ranking-list2 .ranking-timg::after {
        display: none;
    }
</style>
